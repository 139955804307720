import { ExternalLink } from 'Atoms/links/ExternalLink';
import { Link } from 'Atoms/links/Link';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const List = styled.ul`
  padding: 0;
  margin: 0;
  text-align: center;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const ListItem = styled.li`
  text-align: center;
  list-style: none;

  padding: 0 10px 0;
`;

interface FooterLinks {
  className?: string;
  onTermsClick?: () => void;
  onLicenseClick?: () => void;
}

export const FooterLinks: FC<FooterLinks> = ({ className, onTermsClick, onLicenseClick }) => {
  return (
    <List className={className}>
      <ListItem>
        <Link to="/sitemap" color="main" noUnderline>
          Site Map
        </Link>
      </ListItem>
      <ListItem>
        <ExternalLink
          to="https://learn.gideononline.com/help"
          color="main"
          noUnderline
          openInNewTab
        >
          Help
        </ExternalLink>
      </ListItem>
      <ListItem>
        <Link to="#" onClick={onTermsClick} color="main" noUnderline>
          Terms & Conditions
        </Link>
      </ListItem>
      <ListItem>
        <Link to="#" onClick={onLicenseClick} color="main" noUnderline>
          License Agreement
        </Link>
      </ListItem>
      <ListItem>
        <ExternalLink
          to="https://www.gideononline.com/contact"
          color="main"
          noUnderline
          openInNewTab
        >
          Get in touch
        </ExternalLink>
      </ListItem>
    </List>
  );
};

import { Loader } from 'Atoms/Loader';
import { P } from 'Atoms/text';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { CountryPageTitle } from 'Molecules/CountryPageTitle';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { CountryDiseasesSection } from 'Molecules/section/CountryDiseasesSection';
import { CountryGuidelinesSection } from 'Molecules/section/CountryGuidelinesSection';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { VaccinationGraphSection } from 'Molecules/section/VaccinationGraphSection';
import { VaccinationSection } from 'Molecules/section/VaccinationSection';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { useCountriesResource } from 'store/countriesStore/hooks';
import { useCountryDetails } from 'store/countryDetailsStore/hooks';
import { useCountryLatestOutbreaks } from 'store/countryLatestOutbreaksStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import { CustomError, ErrorCodes } from 'types/errorTypes';

type Props = {
  countryId: string;
};

export const Country: FC<Props> = ({ countryId }) => {
  const [showSummary, setShowSummary] = useState(true);
  const [showRelevantDiseases, setShowRelevantDiseases] = useState(true);
  const [showGraph, setShowGraph] = useState(true);
  const [showSchedule, setShowSchedule] = useState(true);
  const [showDownloads, setShowDownloads] = useState(true);

  const countryDetails = useCountryDetails(countryId);
  const latestOutbreaks = useCountryLatestOutbreaks(countryId);
  const countries = useCountriesResource();

  assertIsNotStoreError(countries);
  assertIsNotStoreError(countryDetails.details);
  assertIsNotStoreError(countryDetails.diseases);

  if (
    isLoading(countries) ||
    isLoading(countryDetails.details) ||
    !countryDetails.details ||
    isLoading(countryDetails.diseases) ||
    !countryDetails.diseases ||
    isLoading(latestOutbreaks)
  ) {
    return <Loader />;
  }

  const country = countries.data.find(x => x.id === countryId);

  if (!country) {
    throw new CustomError('Country not found', ErrorCodes.NotFound);
  }

  const sections: RecordLink[] = [
    { label: 'Guidelines', 'aria-label': `${country.name} guidelines` },
    {
      label: `Diseases relevant to ${country.name} (${countryDetails.diseases?.length})`,
      'aria-label': `Diseases relevant to ${country.name} (${countryDetails.diseases?.length})`,
    },
    {
      label: 'Vaccination coverage graph',
      'aria-label': `${country.name} vaccination coverage graph`,
    },
    { label: 'Vaccination schedule', 'aria-label': `${country.name} vaccination schedule` },
    { label: 'Downloads and references', 'aria-label': `${country.name} downloads and references` },
  ];

  const isChartPresent = countryDetails.details.vaccinationChart.some(
    item => item.coordinates.length !== 0
  );

  const description = `GIDEON - ${country.name}. ${sections.join(', ')}`;

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{country.name} - GIDEON</title>
          <meta name="description" content={description} />
        </Helmet>
        <ReferenceLabelContext.Provider value={country.name}>
          <RecordLayout>
            <CountryPageTitle country={country.name} countryId={country.id} />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="country-guidelines"
            >
              <CountryGuidelinesSection
                country={country.name}
                countryDetails={countryDetails.details}
              />
            </RecordSection>
            <RecordSection
              link={sections[1]}
              show={showRelevantDiseases}
              onCollapse={() => setShowRelevantDiseases(!showRelevantDiseases)}
              accent="second"
              id="country-diseases"
            >
              <P>
                Autochtonous cases are reported or have been reported in recent years, or there is a
                likelihood of human disease by virtue of an ongoing presence of the infecting agent
                in local reservoirs/vectors.
              </P>
              <CountryDiseasesSection
                diseases={countryDetails.diseases}
                latestOutbreaks={latestOutbreaks}
                countryId={country.id}
              />
            </RecordSection>
            {isChartPresent && (
              <RecordSection
                link={sections[2]}
                show={showGraph}
                onCollapse={() => setShowGraph(!showGraph)}
                accent="third"
                id="country-graph"
              >
                <VaccinationGraphSection
                  countryName={country.name}
                  data={countryDetails.details.vaccinationChart}
                />
              </RecordSection>
            )}
            <RecordSection
              link={sections[3]}
              show={showSchedule}
              onCollapse={() => setShowSchedule(!showSchedule)}
              accent="fourth"
              id="country-vaccination"
            >
              <VaccinationSection data={countryDetails.details.vaccineSchedule} />
            </RecordSection>
            <RecordSection
              link={sections[4]}
              show={showDownloads}
              onCollapse={() => setShowDownloads(!showDownloads)}
              accent="fifth"
              id="country-download"
            >
              <DownloadsSection id={countryId} category="countries" labelTitle={country.name} />
            </RecordSection>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};

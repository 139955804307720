import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import {
  DiseaseCountries,
  DiseaseDetails,
  DiseaseDetailsSection,
  PublicDiseaseDetails,
} from 'types/disease';
import { Image } from 'types/image';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<
      'DiseaseDetails/SingleLoadInitiated',
      { id: number; type: DiseaseDetailsSection }
    >
  | ActionWithPayload<
      'DiseaseDetails/SingleLoaded',
      {
        id: number;
        data: DiseaseDetails | Image[] | DiseaseCountries | PublicDiseaseDetails;
        type: DiseaseDetailsSection;
      }
    >
  | ActionWithPayload<
      'DiseaseDetails/SingleLoadFailed',
      { id: number; error: StoreError; type: DiseaseDetailsSection }
    >;

export interface DiseaseResource {
  details?: Resource<DiseaseDetails>;
  images?: Resource<Image[]>;
  countries?: Resource<DiseaseCountries>;
  publicDetails?: Resource<PublicDiseaseDetails>;
  publicCountries?: Resource<DiseaseCountries>;
}

export type State = {
  [id: number]: DiseaseResource | undefined;
};

const initialState: State = {};

export const {
  dispatchContext: DiseaseDetailsDispatchContext,
  stateContext: DiseaseDetailsStateContext,
  provider: DiseaseDetailsStoreProvider,
} = storeFactory(reducer, initialState);

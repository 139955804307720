import {
  getDiseaseCountries,
  getDiseaseCountriesPublic,
  getDiseaseDetails,
  getDiseaseDetailsPublic,
  getDiseaseImages,
} from 'apiServices/Diseases/diseases';
import React, { useCallback, useEffect } from 'react';
import { useAccountController } from 'store/accountStore/hooks';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch, isLoading, Loading } from 'store/types';
import { LoadingType } from 'types/common';
import {
  Disease,
  DiseaseCountries,
  DiseaseCountry,
  DiseaseDetails,
  DiseaseDetailsSection,
  PublicDiseaseDetails,
} from 'types/disease';

import {
  Action,
  DiseaseDetailsDispatchContext,
  DiseaseDetailsStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(DiseaseDetailsStateContext);
  if (state === undefined) {
    throw new Error('Disease details store is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(DiseaseDetailsDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Disease details store is not initialized');
  }
  return dispatch;
};

type FetchType =
  | typeof getDiseaseDetails
  | typeof getDiseaseImages
  | typeof getDiseaseCountries
  | typeof getDiseaseDetailsPublic
  | typeof getDiseaseCountriesPublic;

const loadDiseaseResource = async (
  id: number,
  type: DiseaseDetailsSection,
  dispatch: Dispatch<Action>
): Promise<void> => {
  dispatch({ type: 'DiseaseDetails/SingleLoadInitiated', payload: { id, type } });

  const getFetch = (type: DiseaseDetailsSection): FetchType => {
    if (type === 'details') {
      return getDiseaseDetails;
    } else if (type === 'images') {
      return getDiseaseImages;
    } else if (type === 'countries') {
      return getDiseaseCountries;
    } else if (type === 'publicDetails') {
      return getDiseaseDetailsPublic;
    } else {
      return getDiseaseCountriesPublic;
    }
  };

  const fetch = getFetch(type);

  try {
    const data = await fetch(id);
    dispatch({ type: 'DiseaseDetails/SingleLoaded', payload: { id, data, type } });
  } catch (err) {
    dispatch({
      type: 'DiseaseDetails/SingleLoadFailed',
      payload: { id, error: newStoreError(err.message, err.code, err), type },
    });
  }
};

const loadDiseaseResourceIfMissing = async (
  id: number,
  type: DiseaseDetailsSection,
  state: State,
  dispatch: Dispatch<Action>
): Promise<void> => {
  const diseaseInStore = state[id];
  if (!diseaseInStore || !diseaseInStore[type]) {
    await loadDiseaseResource(id, type, dispatch);
  }
};

const loadDiseaseIfMissing = async (
  id: number,
  state: State,
  dispatch: Dispatch<Action>
): Promise<void> => {
  await Promise.all([
    loadDiseaseResourceIfMissing(id, 'details', state, dispatch),
    loadDiseaseResourceIfMissing(id, 'images', state, dispatch),
    loadDiseaseResourceIfMissing(id, 'countries', state, dispatch),
  ]);
};

const isLoaded = <T>(data: T | LoadingType | undefined): data is T => !!data && !isLoading(data);

export const useDiseaseDetails = (id: number): Disease | 'Loading' => {
  const state = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    loadDiseaseIfMissing(id, state, dispatch);
  }, [id, state, dispatch]);

  const details = state[id]?.details;
  const images = state[id]?.images;
  const countries = state[id]?.countries;

  assertIsNotStoreError(details);
  assertIsNotStoreError(images);
  assertIsNotStoreError(countries);

  if (isLoaded(details) && isLoaded(images) && isLoaded(countries)) {
    return {
      details,
      images,
      countries,
    };
  }

  return Loading;
};

export const useGetDiseaseCountries = (): ((id: number) => DiseaseCountry[]) => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  const getDiseaseCountries = useCallback(
    (id: number): DiseaseCountry[] => {
      loadDiseaseResourceIfMissing(
        id,
        isAuthenticated ? 'countries' : 'publicCountries',
        state,
        dispatch
      );

      const countries = isAuthenticated ? state[id]?.countries : state[id]?.publicCountries;

      assertIsNotStoreError(countries);

      if (!countries || isLoading(countries)) {
        return [];
      }

      return countries.list;
    },
    [dispatch, isAuthenticated, state]
  );

  return getDiseaseCountries;
};

export const useMultipleDiseaseDetails = (ids: number[]): (DiseaseDetails | 'Loading')[] => {
  const state = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    ids.forEach(id => {
      loadDiseaseResourceIfMissing(id, 'details', state, dispatch);
    });
  }, [ids, state, dispatch]);

  return ids.reduce((acc: (DiseaseDetails | 'Loading')[], id) => {
    const details = state[id]?.details;

    assertIsNotStoreError(details);
    return [...acc, details || Loading];
  }, []);
};

export const useDiseaseDetailsPublic = (id: number): PublicDiseaseDetails | 'Loading' => {
  const state = useState();
  const dispatch = useDispatch();

  const publicDetails = state[id]?.publicDetails;

  useEffect(() => {
    loadDiseaseResourceIfMissing(id, 'publicDetails', state, dispatch);
  }, [dispatch, id, state]);

  assertIsNotStoreError(publicDetails);

  return isLoaded(publicDetails) ? publicDetails : Loading;
};

export const useDiseaseCountriesPublic = (id: number): DiseaseCountries | 'Loading' => {
  const state = useState();
  const dispatch = useDispatch();

  const publicCountries = state[id]?.publicCountries;

  useEffect(() => {
    loadDiseaseResourceIfMissing(id, 'publicCountries', state, dispatch);
  }, [dispatch, id, state]);

  assertIsNotStoreError(publicCountries);

  return isLoaded(publicCountries) ? publicCountries : Loading;
};

import { Link } from 'Atoms/links/Link';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import { Country } from 'types/country';
import { DynamicContent } from 'types/dynamicContent';
import { SimpleDisease } from 'types/simpleDisease';

interface Props {
  note: DynamicContent;
  countries: Country[];
  diseases: SimpleDisease[];
  disease: string;
  diseaseId: number;
}

export const CountryNoteSection: FC<Props> = ({
  note,
  countries,
  diseases,
  disease,
  diseaseId,
}) => {
  return (
    <>
      <DynamicHtml content={note} />
      <br />
      <br />
      {countries.length > 0 && (
        <>
          <b>Related countries</b>
          <ul>
            {countries.map(c => (
              <li key={c.id}>
                <Link to={`/explore/diseases/${diseaseId}/${c.id}`}>
                  {disease} in {c.name}
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
      <br />
      {diseases.length > 0 && (
        <>
          <b>Related diseases</b>
          <ul>
            {diseases.map(d => (
              <li key={d.id}>
                <Link to={`/explore/diseases/${d.id}`}>{d.name}</Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};

import { Link } from 'Atoms/links/Link';
import { P } from 'Atoms/text/P';
import React, { FC } from 'react';
import {
  getLatestOutbreakBioterrorismLabel,
  getLatestOutbreakLabel,
} from 'services/getLatestOutbreakLabel';
import styled from 'styled-components/macro';
import { CountryDisease } from 'types/countryDetails';
import { LatestOutbreak } from 'types/countryLatestOutbreaks';
import { groupDataAlphabetically } from 'utils/getGroupedData';

const Wrapper = styled.div`
  column-count: 5;
  column-width: 300px;
  column-gap: 20px;
  padding: 20px 0;
`;

const Group = styled.div`
  break-inside: avoid-column;
  display: table;
  margin: 20px 0 0 0;
  &:first-child {
    margin-top: 0;
  }
`;

const GroupItem = styled.div`
  display: block;
  margin: 5px 0;
`;

const OutbreakLabel = styled(P)`
  margin-top: 5px;
`;

interface Props {
  diseases: CountryDisease[];
  latestOutbreaks?: LatestOutbreak[];
  countryId: string;
}

export const CountryDiseasesSection: FC<Props> = ({ diseases, countryId, latestOutbreaks }) => {
  const groupedList = groupDataAlphabetically(diseases, true);

  return (
    <Wrapper>
      {Object.keys(groupedList)
        .sort()
        .map((key: string) => (
          <Group key={key}>
            <P weight="700" size="normal">
              {key}
            </P>
            {groupedList[key].map(item => {
              const outbreakLabel =
                countryId === 'G90'
                  ? getLatestOutbreakBioterrorismLabel(item.id, latestOutbreaks)
                  : getLatestOutbreakLabel(item.id, latestOutbreaks);

              return (
                <GroupItem key={item.id}>
                  <Link to={`/explore/diseases/${item.id}/${countryId}`}>{item.name}</Link>
                  {outbreakLabel && <OutbreakLabel>{outbreakLabel}</OutbreakLabel>}
                </GroupItem>
              );
            })}
          </Group>
        ))}
    </Wrapper>
  );
};
